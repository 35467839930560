<template>
  <div class="loader-content">
    <div class="loader" v-if="progress"></div>
    <div class="loader-two" v-else><div class="circle"></div></div>
  </div>
</template>
<script>
export default {
  props: {
    progress: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.loader-content {
  position: relative;
  min-height: 100vh;

  .loader {
    position: absolute;
    top: 40%;
    left: 50%;

    width: 10px;
    height: 20px;
    margin-left: 20px;
    margin-right: 20px;
    background: #3b82f6;

    &:before,
    &:after {
      content: "";
      position: absolute;
    }

    &:before {
      top: -8px;
      left: -13px;
      width: 0;
      height: 0;
      border: 18px solid transparent;
      border-bottom: 20px solid #3b82f6;
      border-radius: 3px;
    }

    &:after {
      top: 0;
      left: 0;
      width: 4px;
      height: 4px;
      background: #3b82f6;
      border-radius: 50%;
      animation: loader-bubbles 1s linear infinite forwards;
    }

    @keyframes loader-bubbles {
      0% {
        box-shadow: 0 -10px #3b82f6, 3px 0 #3b82f6, 5px 0 #3b82f6;
      }

      30% {
        box-shadow: 3px -20px rgba(239, 223, 255, 0), 5px -10px #3b82f6,
          5px 0 #3b82f6;
      }

      60% {
        box-shadow: 3px 0 rgba(239, 223, 255, 0),
          4px -20px rgba(239, 223, 255, 0), 3px -10px #3b82f6;
      }

      61% {
        box-shadow: 3px 0 #3b82f6, 4px -20px rgba(239, 223, 255, 0),
          3px -10px #3b82f6;
      }

      100% {
        box-shadow: 0 -10px #3b82f6, 4px -20px rgba(239, 223, 255, 0),
          5px -20px rgba(239, 223, 255, 0);
      }
    }
  }

  .loader-two {
    position: absolute;
    top: 40%;
    left: 50%;
    border: 5px solid transparent;
    border-bottom: 5px solid #3b82f6;
    padding: 1em;
    border-radius: 50%;
    transform: rotate(180deg);

    animation-name: loader-two;
    animation-duration: 0.9s;
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-iteration-count: infinite;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;

    @keyframes loader-two {
      0% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(-180deg);
      }
    }
  }

  .circle {
    border-radius: 0.25em;
    width: 1em;
    height: 1em;
    background-color: #3b82f6;

    animation-name: circle;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-iteration-count: infinite;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;

    @keyframes circle {
      0% {
        transform: scale(1);
      }
      25% {
        transform: scale(1.1);
      }
      50% {
        transform: scale(2.5);
      }
      75% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
</style>
