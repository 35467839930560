<script>
import {
  BButton,
  BCard,
  BCol,
  BListGroup,
  BListGroupItem,
  BPopover,
  BRow,
} from "bootstrap-vue";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import {mapState} from "vuex";
import TemplateNavMenu from "@/views/components/whitelabel-templates/common/TemplateNavMenu.vue";
import TemplateSizeSelector from "@/views/components/whitelabel-templates/common/TemplateSizeSelector.vue";
import UserProfile from "@/views/components/whitelabel-templates/common/UserProfile/UserProfile.vue";
import GodMoneyCharger from "@/views/components/whitelabel-templates/GodMoney/GodMoneyCharger.vue";
import GMGeneralEdit from "@/views/components/whitelabel-templates/GodMoney/config/GeneralEdit/GeneralEdit.vue";
import GMPageEdit from "@/views/components/whitelabel-templates/GodMoney/config/PageEdit/PageEdit.vue";
import LoaderTemplate from "@/views/components/whitelabel-templates/common/loaderTemplate.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {translatableText} from "@core/utils/utils";
import {axiosErrorHandle} from "@core/utils/errorHandler";

const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

export default {
  name: "index",
  components: {
    GodMoneyCharger,
    UserProfile,
    TemplateNavMenu,
    TemplateSizeSelector,
    BPopover,
    BListGroupItem,
    BListGroup,
    BButton,

    BCol,
    BRow,
    BCard,
    GMPageEdit,
    GMGeneralEdit,
    LoaderTemplate
  },
  data() {
    return {
      size: "monitor",
      errorFetchingTemplate: false,
      menu: [
        {icon: "EditIcon", window: "g-edit", name: "General edit"},
        {icon: "ColumnsIcon", window: "p-edit", name: "Layout edit"},
      ],
      selectedMenuWindow: "",
      hoverEditComponent: "",
      closed: false,
      template: {
        templateData: {
          header: {
            name: "Header GodMoney",
            background_color: "#040713",
            text_color: "",
            color: "",
            background_image:
                "https://whish-whitelabel-api.kingconnections.net/api/media/default/header/backgroud-image.jpg",
            auth: {
              background_color: "#121212",
              color: "#60e003",
              text_color: "#ffffff",
              hide: false,
            },
            logo: {
              href: "img/godmoney/logo.png",
              position: 2,
              with: "20",
              height: "50",
              hide: false,
            },
            whitelabelId: "632882e92f2e14ac4aed3ca7",
            status: true,
            softDelete: null,
            isReadonly: false,
            _id: "66bf799fc15ca3dadbbe55bf",
            createdAt: "2024-08-16T16:09:03.726Z",
            updatedAt: "2024-08-16T16:09:03.726Z",
            __v: 0,
          },
          menu: {
            name: "Menu test105 66bf799fc15ca3dadbbe55bd",
            background_color: "rgba(3,3,3,0)",
            background_image: null,
            color: "#ffffff",
            hover_color: "#D3AD4E",
            font_size: 14,
            title: "menu",
            is_desplegable: false,
            links: [
              {
                id: "631f67432bf96f9ebfb20195",
                href: "/product/sportsbook",
                title: "DEPORTES",
                position: 1,
                buttonType: "product",
                children: [],
                _id: "631f67432bf96f9ebfb20195",
              },
              {
                id: "631f67432bf96f9ebfb20196",
                href: "/product/centerhorses",
                title: "CABALLOS",
                position: 2,
                buttonType: "product",
                children: [],
                _id: "631f67432bf96f9ebfb20196",
              },
              {
                id: "631f67432bf96f9ebfb20197",
                href: "/product/sw3",
                title: "DEPORTES EN VIVO",
                position: 1,
                buttonType: "product",
                children: [],
                _id: "631f67432bf96f9ebfb20197",
              },
              {
                id: "64e757d26af1734f2864943a",
                position: 4,
                buttonType: "lobby",
                title: "LIVE CASINO",
                href: "/lobby?id=632cc73d5eaacc62c76a89a8",
                _id: "64e757d26af1734f2864943a",
              },
              {
                id: "656632a2289a5d3a2865483f",
                position: 0,
                buttonType: "lobby",
                title: "Hector test",
                href: "/lobby?id=656632a2289a5d3a2865483f",
                _id: "656632a2289a5d3a2865483f",
              },
              {
                id: "632cc73d5eaacc62c76a89a7",
                position: 0,
                buttonType: "lobby",
                title: "CASINO",
                href: "/lobby?id=632cc73d5eaacc62c76a89a7",
                _id: "632cc73d5eaacc62c76a89a7",
              },
            ],
            whitelabelId: "632882e92f2e14ac4aed3ca7",
            status: false,
            softDelete: null,
            isReadonly: false,
            _id: "66bf799fc15ca3dadbbe55c5",
            createdAt: "2024-08-16T16:09:03.747Z",
            updatedAt: "2024-08-16T16:09:03.747Z",
            __v: 0,
          },
          main: {
            colors: {
              bodyColor: "#000000",
              backgroundColor: "#000000",
              primaryColor: "#4caf50",
              placeHolderColor: "#449b47",
              secondaryColor: "rgb(76, 41, 126)",
              secondaryAltColor: "rgb(66, 45, 122)",
              paraColor: "rgba(255, 255, 255, 0.8)",
              paraAltColor: "#076686",
              sectionColor: "rgb(27, 12, 67)",
              bsWhite: "#fff",
              textColor: "#fff",
            },
            font: "Noto Sans, sans-serif",
          },
          socialLinks: {
            title: "Síguenos en nuestras redes sociales!",
            links: [
              {
                href: "https://facebook.com/example",
                svgData:
                    " <svg\n" +
                    '              fill="#fff"\n' +
                    '              height="15px"\n' +
                    '              width="15px"\n' +
                    '              version="1.1"\n' +
                    '              id="Layer_1"\n' +
                    '              xmlns="http://www.w3.org/2000/svg"\n' +
                    '              xmlns:xlink="http://www.w3.org/1999/xlink"\n' +
                    '              viewBox="0 0 310 310"\n' +
                    '              xml:space="preserve"\n' +
                    "          >\n" +
                    '              <g id="XMLID_834_">\n' +
                    "                <path\n" +
                    '                    id="XMLID_835_"\n' +
                    '                    d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064\n' +
                    "\t\tc2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996\n" +
                    "\t\tV71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545\n" +
                    "\t\tC187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703\n" +
                    '\t\tc-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"\n' +
                    "                />\n" +
                    "              </g>\n" +
                    "            </svg>",
              },
              {
                href: "https://instagram.com/example",
                svgData:
                    "          <svg\n" +
                    '              width="15px"\n' +
                    '              height="15px"\n' +
                    '              viewBox="0 0 24 24"\n' +
                    '              fill="none"\n' +
                    '              xmlns="http://www.w3.org/2000/svg"\n' +
                    "          >\n" +
                    "            <path\n" +
                    '                fill-rule="evenodd"\n' +
                    '                clip-rule="evenodd"\n' +
                    '                d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"\n' +
                    '                fill="#fff"\n' +
                    "            />\n" +
                    "            <path\n" +
                    '                d="M18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5Z"\n' +
                    '                fill="#fff"\n' +
                    "            />\n" +
                    "            <path\n" +
                    '                fill-rule="evenodd"\n' +
                    '                clip-rule="evenodd"\n' +
                    '                d="M1.65396 4.27606C1 5.55953 1 7.23969 1 10.6V13.4C1 16.7603 1 18.4405 1.65396 19.7239C2.2292 20.8529 3.14708 21.7708 4.27606 22.346C5.55953 23 7.23969 23 10.6 23H13.4C16.7603 23 18.4405 23 19.7239 22.346C20.8529 21.7708 21.7708 20.8529 22.346 19.7239C23 18.4405 23 16.7603 23 13.4V10.6C23 7.23969 23 5.55953 22.346 4.27606C21.7708 3.14708 20.8529 2.2292 19.7239 1.65396C18.4405 1 16.7603 1 13.4 1H10.6C7.23969 1 5.55953 1 4.27606 1.65396C3.14708 2.2292 2.2292 3.14708 1.65396 4.27606ZM13.4 3H10.6C8.88684 3 7.72225 3.00156 6.82208 3.0751C5.94524 3.14674 5.49684 3.27659 5.18404 3.43597C4.43139 3.81947 3.81947 4.43139 3.43597 5.18404C3.27659 5.49684 3.14674 5.94524 3.0751 6.82208C3.00156 7.72225 3 8.88684 3 10.6V13.4C3 15.1132 3.00156 16.2777 3.0751 17.1779C3.14674 18.0548 3.27659 18.5032 3.43597 18.816C3.81947 19.5686 4.43139 20.1805 5.18404 20.564C5.49684 20.7234 5.94524 20.8533 6.82208 20.9249C7.72225 20.9984 8.88684 21 10.6 21H13.4C15.1132 21 16.2777 20.9984 17.1779 20.9249C18.0548 20.8533 18.5032 20.7234 18.816 20.564C19.5686 20.1805 20.1805 19.5686 20.564 18.816C20.7234 18.5032 20.8533 18.0548 20.9249 17.1779C20.9984 16.2777 21 15.1132 21 13.4V10.6C21 8.88684 20.9984 7.72225 20.9249 6.82208C20.8533 5.94524 20.7234 5.49684 20.564 5.18404C20.1805 4.43139 19.5686 3.81947 18.816 3.43597C18.5032 3.27659 18.0548 3.14674 17.1779 3.0751C16.2777 3.00156 15.1132 3 13.4 3Z"\n' +
                    '                fill="#fff"\n' +
                    "            />\n" +
                    "          </svg>\n",
              },
              {
                href: "https://linkedin.com/company/example",
                svgData:
                    "          <svg\n" +
                    '              fill="#fff"\n' +
                    '              width="15px"\n' +
                    '              height="15px"\n' +
                    '              viewBox="0 0 32 32"\n' +
                    '              version="1.1"\n' +
                    '              xmlns="http://www.w3.org/2000/svg"\n' +
                    "          >\n" +
                    "            <path\n" +
                    '                d="M28.778 1.004h-25.56c-0.008-0-0.017-0-0.027-0-1.199 0-2.172 0.964-2.186 2.159v25.672c0.014 1.196 0.987 2.161 2.186 2.161 0.010 0 0.019-0 0.029-0h25.555c0.008 0 0.018 0 0.028 0 1.2 0 2.175-0.963 2.194-2.159l0-0.002v-25.67c-0.019-1.197-0.994-2.161-2.195-2.161-0.010 0-0.019 0-0.029 0h0.001zM9.9 26.562h-4.454v-14.311h4.454zM7.674 10.293c-1.425 0-2.579-1.155-2.579-2.579s1.155-2.579 2.579-2.579c1.424 0 2.579 1.154 2.579 2.578v0c0 0.001 0 0.002 0 0.004 0 1.423-1.154 2.577-2.577 2.577-0.001 0-0.002 0-0.003 0h0zM26.556 26.562h-4.441v-6.959c0-1.66-0.034-3.795-2.314-3.795-2.316 0-2.669 1.806-2.669 3.673v7.082h-4.441v-14.311h4.266v1.951h0.058c0.828-1.395 2.326-2.315 4.039-2.315 0.061 0 0.121 0.001 0.181 0.003l-0.009-0c4.5 0 5.332 2.962 5.332 6.817v7.855z"\n' +
                    "            ></path>\n" +
                    "          </svg>\n",
              },
              {
                href: "https://twitter.com/example",
                svgData:
                    " <svg\n" +
                    '              width="15px"\n' +
                    '              height="15px"\n' +
                    '              viewBox="0 -2 20 20"\n' +
                    '              version="1.1"\n' +
                    '              xmlns="http://www.w3.org/2000/svg"\n' +
                    '              xmlns:xlink="http://www.w3.org/1999/xlink"\n' +
                    "          >\n" +
                    "            <defs></defs>\n" +
                    "            <g\n" +
                    '                id="Page-1"\n' +
                    '                stroke="none"\n' +
                    '                stroke-width="1"\n' +
                    '                fill="none"\n' +
                    '                fill-rule="evenodd"\n' +
                    "            >\n" +
                    "              <g\n" +
                    '                  id="Dribbble-Light-Preview"\n' +
                    '                  transform="translate(-60.000000, -7521.000000)"\n' +
                    '                  fill="#fff"\n' +
                    "              >\n" +
                    '                <g id="icons" transform="translate(56.000000, 160.000000)">\n' +
                    "                  <path\n" +
                    '                      d="M10.29,7377 C17.837,7377 21.965,7370.84365 21.965,7365.50546 C21.965,7365.33021 21.965,7365.15595 21.953,7364.98267 C22.756,7364.41163 23.449,7363.70276 24,7362.8915 C23.252,7363.21837 22.457,7363.433 21.644,7363.52751 C22.5,7363.02244 23.141,7362.2289 23.448,7361.2926 C22.642,7361.76321 21.761,7362.095 20.842,7362.27321 C19.288,7360.64674 16.689,7360.56798 15.036,7362.09796 C13.971,7363.08447 13.518,7364.55538 13.849,7365.95835 C10.55,7365.79492 7.476,7364.261 5.392,7361.73762 C4.303,7363.58363 4.86,7365.94457 6.663,7367.12996 C6.01,7367.11125 5.371,7366.93797 4.8,7366.62489 L4.8,7366.67608 C4.801,7368.5989 6.178,7370.2549 8.092,7370.63591 C7.488,7370.79836 6.854,7370.82199 6.24,7370.70483 C6.777,7372.35099 8.318,7373.47829 10.073,7373.51078 C8.62,7374.63513 6.825,7375.24554 4.977,7375.24358 C4.651,7375.24259 4.325,7375.22388 4,7375.18549 C5.877,7376.37088 8.06,7377 10.29,7376.99705"\n' +
                    '                      id="twitter-[#154]"\n' +
                    "                  ></path>\n" +
                    "                </g>\n" +
                    "              </g>\n" +
                    "            </g>\n" +
                    "          </svg>",
              },
            ],
          },
          heroSwiper: {
            images: [
              {
                src: "img/godmoney/new/banners/01-godluck.jpg",
              },
              {
                src: "img/godmoney/new/banners/02-godluck.jpg",
              },
              {
                src: "img/godmoney/new/banners/03-godluck.jpg",
              },
              {
                src: "img/godmoney/new/banners/04-godluck.jpg",
              },
            ],
          },
          featuredSelection: {
            title: "NUESTRA SELECCIÓN PARA TÍ",
            items: [
              {
                src: "/img/godmoney/new/left-0.png",
                alt: "Featured selection 1",
              },
              {
                src: "/img/godmoney/new/right-0.png",
                alt: "Featured selection 2",
              },
            ],
          },
          sportsBetting: {
            title: "Apuestas Deportivas",
            link: "/sports",
            items: [
              {
                src: "img/godmoney/new/banner-md-sports-godluck.jpg",
              },
            ],
          },
          slotGames: {
            title: "Más de 2000 Slots",
            games: [
              {
                src: "/img/godmoney/Juegos-recomendados/slot_001.png",
              },
              {
                src: "/img/godmoney/Juegos-recomendados/slot_002.png",
              },
              {
                src: "/img/godmoney/Juegos-recomendados/slot_003.png",
              },
            ],
          },
          liveTables: {
            title: "Mesas en Vivo Seleccionadas",
            tables: [
              {
                src: "/img/godmoney/new/banner_verticales_1.png",
                link: "/live-table/1",
              },
              {
                src: "/img/godmoney/new/banner_verticales_2.png",
                link: "/live-table/2",
              },
              {
                src: "/img/godmoney/new/banner_verticales_3.png",
                link: "/live-table/3",
              },
              {
                src: "/img/godmoney/new/banner_verticales_4.png",
                link: "/live-table/4",
              },
              {
                src: "/img/godmoney/new/banner_verticales_5.png",
                link: "/live-table/5",
              },
            ],
          },
          user_profile: {
            colors: {
              bgPrimary: "#1b1d1f",
              bgSecondary: "#101213",
              colorBorder: "#1b1d1f",
              colorPrimary: "#3bc117",
              colorPrimaryText: "#fff",
              colorSecondary: "#aa0",
            },
          },
          footer: {
            _id: "66f70d6d0b27810d8b2f3644",
            name: "Footer test400",
            background_color: "#111415",
            color: "#fff",
            primary_color: "#D3AD4E",
            left: {
              image:
                  "https://s3-images-api.kbapi.net/cdnbc/18/assets/logos/38f80cb9-a92e-4466-8700-ce1192c6351c.png",
              items: [
                {
                  content: "© Casinoapostala.pro - 2024",
                  href: "",
                },
                {
                  content: "Todos los derechos reservados",
                  href: "",
                },
              ],
            },
            right: {
              sections: [
                {
                  title: "Secciones",
                  items: [
                    {
                      content: "Superslots",
                      href: "",
                    },
                    {
                      content: "Casino Vivo",
                      href: "",
                    },
                    {
                      content: "Deportes",
                      href: "",
                    },
                    {
                      content: "Deportes En Vivo",
                      href: "",
                    },
                  ],
                },
                {
                  title: "Juegos",
                  items: [
                    {
                      content: "Caballos",
                      href: "",
                    },
                    {
                      content: "E-sports",
                      href: "",
                    },
                  ],
                },
                {
                  title: "Deportivas",
                  items: [
                    {
                      content: "Reglamento",
                      href: "",
                    },
                    {
                      content: "Casino Vivo",
                      href: "",
                    },
                    {
                      content: "Deportes",
                      href: "",
                    },
                    {
                      content: "Deportes En Vivo",
                      href: "",
                    },
                  ],
                },
              ],
            },
            whitelabelId: "632882e92f2e14ac4aed3ca7",
            status: true,
            softDelete: null,
            isReadonly: false,
            createdAt: "2024-09-27T19:54:21.159Z",
            updatedAt: "2024-09-30T03:25:31.864Z",
            __v: 0,
            templateId: "66f70d6d0b27810d8b2f3653",
          },
          responsible_gaming: {
            images: [
              {
                src: "https://godluck.pro/assets/template/godmoney/img/new/18.svg",
                href: "",
              },
              {
                src: "https://godluck.pro/assets/template/godmoney/img/new/18.svg",
                href: "",
              },
            ],
            title:
                " Juega responsablemente. Para mayor información visita: GamblingTherapy.org",
          },
        },
      },
    };
  },
  computed: {
    ...mapState(MODULE_APP_STORE_NAME, [
      "loadingFetchTemplate",
      "editTemplate",
      "reRenderTemplate",
    ]),

    getWhitelabelSelect() {
      return store.state.whitelabelCurrencyNabvar.whitelabel;
    },
  },

  watch: {
    getWhitelabelSelect: {
      handler(newWhitelabel, oldWhitelabel) {
        if (newWhitelabel._id !== oldWhitelabel._id) this.fetchTemplateData();
      },
    },
  },

  methods: {
    onToggleSidebar(closed) {
      this.closed = closed;
    },

    handleMenuWindow(menu) {
      this.selectedMenuWindow = this.selectedMenuWindow === menu ? "" : menu;
    },

    async fetchTemplateData() {
      const id = this.$route.query.id;
      const params = {
        whitelabelId: this.getWhitelabelSelect._id,
      };
      this.errorFetchingTemplate = false;
      try {
        let {data: template} = await this.$store.dispatch(
            `${MODULE_APP_STORE_NAME}/fetchTemplate`,
            {id, params}
        );

        this.$store.commit(
            `${MODULE_APP_STORE_NAME}/SET_EDIT_TEMPLATE`,
            template
        );
        this.$store.commit(
            `${MODULE_APP_STORE_NAME}/SET_EDIT_TEMPLATE_RECOVERY`,
            JSON.parse(JSON.stringify(template))
        );
      } catch (e) {
        this.errorFetchingTemplate = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: await translatableText({text: axiosErrorHandle(e).toString()}),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        //TODO DELETE FINALLY
        // this.$store.commit(
        //     "app-whitelabel-templates/SET_EDIT_TEMPLATE",
        //     this.template
        // );
        // this.$store.commit(
        //     "app-whitelabel-templates/SET_EDIT_TEMPLATE_RECOVERY",
        //     JSON.parse(JSON.stringify(this.template))
        // );
        // this.errorFetchingTemplate = false;
      }
    },
  },

  created() {
    store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },

  async mounted() {
    this.fetchTemplateData();
  },

  beforeCreate() {
    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
  },

  // beforeDestroy() {
  //   if (store.hasModule(MODULE_APP_STORE_NAME))
  //     store.unregisterModule(MODULE_APP_STORE_NAME);
  // },
};
</script>

<template>
  <div class="template-container">
    <!-- Loading status -->
    <!-- <div v-if="loadingFetchTemplate" class="loading-state">Cargando...</div> -->
    <LoaderTemplate v-if="loadingFetchTemplate"/>

    <!-- Error status -->
    <div v-else-if="errorFetchingTemplate" class="error-state">
      Error, Reintentar...
    </div>

    <div v-else-if="editTemplate" class="main-content d-flex flex-column">
      <!-- Size selector -->
      <template-size-selector
          :sizes="['smartphone', 'tablet', 'square', 'monitor']"
          @selectSize="(size) => (this.size = size)"
      />

      <section class="wrapper">
        <!-- Application loading component -->
        <god-money-charger
            :hover-edit-component="hoverEditComponent"
            :re-render="reRenderTemplate"
            :size="size"
        />

        <!-- Navigation Container -->
        <TemplateNavMenu
            :menu="menu"
            :selected-menu-window="selectedMenuWindow"
            :closed="closed"
            @menu-window-selected="handleMenuWindow"
            @toggle-sidebar="onToggleSidebar"
        />

        <!-- Editing components -->
        <g-m-general-edit v-show="selectedMenuWindow === 'g-edit'"/>
        <g-m-page-edit v-show="selectedMenuWindow === 'p-edit'"/>
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.main-content {
  height: 80vh;
  gap: 5px;
}

.size-selector {
  padding: 10px;
}
</style>
