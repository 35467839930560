<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";
import vSelect from "vue-select";
import i18n from "@/libs/i18n";
import kbStoreModule from "@/views/components/whitelabel-templates/GodMoney/store/gmStoreModule";
import {UUIDGenerator} from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import {RequestBuilder} from "@core/utils/requestBuilder";
import footerStoreModule
  from "@/views/components/whitelabel-templates/GodMoney/config/PageEdit/FooterManagement/store/footerStoreModule";
import {axiosErrorHandle} from "@core/utils/errorHandler";

import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import LeftSection
  from "@/views/components/whitelabel-templates/GodMoney/config/PageEdit/FooterManagement/components/leftSection.vue";
import RightSection
  from "@/views/components/whitelabel-templates/GodMoney/config/PageEdit/FooterManagement/components/rightSection.vue";

export default {
  name: "index",
  components: {
    RightSection,
    LeftSection,
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "footer",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const FOOTER_AP_STORE_MODULE_NAME = "gm-footer-store";
    const MODULE_AP_STORE_NAME = "gm-store";

    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(FOOTER_AP_STORE_MODULE_NAME))
      store.registerModule(FOOTER_AP_STORE_MODULE_NAME, footerStoreModule);
    if (!store.hasModule(MODULE_AP_STORE_NAME))
      store.registerModule(MODULE_AP_STORE_NAME, kbStoreModule);

    const footer = ref(null);
    const loading = ref(false);
    const rBuilder = RequestBuilder();
    const toast = useToast();
    const debounceTimer = ref(null);

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetFooter();
      const {itemsSelected} = props.options;
      if (itemsSelected) footer.value = itemsSelected;
      updatePreview();
    });

    const resetFooter = () => {
      footer.value = {
        name: "",
        background_color: '#000',
        background_image: "",
        color: '#fff',
        left: {
          image: '',
          items: []
        },
        right: {
          sections: []
        }
      };
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateFooter();
      } else {
        createFooter();
      }
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            `${MODULE_APP_STORE_NAME}/updateTemplate`,
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.footer'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createFooter = async () => {
      loading.value = true;
      footer.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      footer.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            `${FOOTER_AP_STORE_MODULE_NAME}/addFooter`,
            rBuilder.clean(footer.value)
        );

        showSuccessToast(toast, "Footer", i18n.t("footer_created"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_footer"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateFooter = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            `${FOOTER_AP_STORE_MODULE_NAME}/updateFooter`,
            {
              id: footer.value._id,
              footerData: rBuilder.clean(footer.value),
            }
        );

        showSuccessToast(toast, "Footer", i18n.t("footer_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_footer"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const onUploadedImage = (data, key) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        const keys = key.split(".");
        let current = footer.value;
        for (let i = 0; i < keys.length - 1; i++) {
          current = current[keys[i]];
        }
        current[keys[keys.length - 1]] = image || '';
        updatePreview();
      }
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          footer: value || footer.value
        }
      };
      store.commit(`${MODULE_APP_STORE_NAME}/SET_EDIT_TEMPLATE`, updatedTemplate);
    };

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.footer");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const onDeleteLeftItem = (index) => {
      footer.value.left.items.splice(index, 1);
      updatePreview();
    }

    const onDeleteRightSection = (index) => {
      footer.value.right.sections.splice(index, 1);
      updatePreview();
    }

    const onEditLeftItem = ({value, index}) => {
      footer.value.left.items[index] = {...value}
      updatePreview();
    }

    const onAddLeftItem = (item) => {
      footer.value.left.items.push(item)
      updatePreview();
    }

    const onAddRightSection = (section) => {
      footer.value.right.sections.push(section)
      updatePreview();
    }

    const onEditRightSection = ({value, index}) => {
      footer.value.right.sections[index] = {...value}
      updatePreview();
    }

    const onClearImage = (key) => {
      const keys = key.split(".");
      let current = footer.value;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = '';
      updatePreview();
    }

    const onRSectionChange = ({section, index}) => {
      const sections = footer.value.right.sections
      sections[index] = section

      footer.value = {
        ...footer.value,
        right: {
          sections: sections
        }
      }

      updatePreview();
    }

    const onLSectionChange = ({item, index}) => {
      const items = footer.value.left.items
      items[index] = item

      footer.value = {
        ...footer.value,
        left: {
          items
        }
      }

      updatePreview();
    }

    const onColorChange = ({key, value}) => {
      const keys = key.split(".");
      let current = footer.value;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 500);
    };


    return {
      isEditMode,
      onSubmit,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      footer,
      onLSectionChange,
      onUploadedImage,
      errorInLoadImage,
      updatePreview,
      onDeleteLeftItem,
      onEditLeftItem,
      onAddLeftItem,
      onDeleteRightSection,
      onAddRightSection,
      onEditRightSection,
      onClearImage,
      onColorChange,
      onRSectionChange,
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          v-if="footer"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="s-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input id="s-name" type="text" v-model="footer.name"/>
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <!--NOT SHOW-->
              <image-field-form
                  style="display: none !important"
                  :single-button="true"
                  :text="$t('labels.backgroundimage')"
                  class="w-100 mt-1"
                  @uploadedImage="onUploadedImage($event, 'background_image')"
                  @error="errorInLoadImage"
                  path="footers"
              >
              </image-field-form>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                  #default="validationContext"
                  name="BackgroundColor"
                  rules="required"
              >
                <b-form-group
                    class="mt-1"
                    label="Background color"
                    label-for="f-backgroundColor"
                >
                  <template #label>{{ $t("labels.backgroundcolor") }}</template>
                  <b-form-input
                      @input="(newValue) => onColorChange({key: 'background_color', value: newValue})"
                      id="f-backgroundColor"
                      type="color"
                      v-model="footer.background_color"
                      :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                  #default="validationContext"
                  name="color"
              >
                <b-form-group
                    class="mt-1"
                    label="color"
                    label-for="f-color"
                >
                  <template #label>{{ $t("text_color") }}</template>
                  <b-form-input
                      id="f-color"
                      type="color"
                      @input="(newValue) => onColorChange({key: 'color', value: newValue})"
                      v-model="footer.color"
                      :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <span>{{ $t('labels.backgroundimage') }}</span> <br/>
              <span>400x400</span>
              <image-field-form
                  :single-button="true"
                  :text="$t('labels.backgroundimage')"
                  class="w-100 mt-1"
                  :value="footer.background_image"
                  size-validation="200x100"
                  path="footers"
                  @uploadedImage="onUploadedImage($event, 'background_image')"
                  @error="errorInLoadImage"
                  preview="true"
              >
              </image-field-form>

              <div v-if="footer.background_image" class="w-100">
                <img
                    style="max-width: 100%"
                    :src="footer.background_image"
                    alt="Uploaded Image"
                    class="mt-1 rounded"
                />
                <b-button class="close-btn" @click="onClearImage('background_image')" variant="outline-primary"
                          size="sm">
                  X
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="12">
              <span>{{ $t('labels.logo') }}</span> <br/>
              <span>200x100</span>
              <image-field-form
                  :single-button="true"
                  :text="$t('labels.logo')"
                  class="w-100 mt-1"
                  :value="footer.left.image"
                  path="footers/logo"
                  size-validation="200x100"
                  @uploadedImage="onUploadedImage($event, 'left.image')"
                  @error="errorInLoadImage"
                  preview="true"
              >
              </image-field-form>

              <div v-if="footer.left.image" class="w-100">
                <img
                    style="max-width: 100%"
                    :src="footer.left.image"
                    alt="Uploaded Image"
                    class="mt-1 rounded"
                />
                <b-button class="close-btn" @click="onClearImage('left.image')" variant="outline-primary" size="sm">
                  X
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Settings">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-0">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <h6 v-b-toggle.accordion-1> Left Section Items</h6>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <left-section @saveItem="onAddLeftItem($event)"
                              @editItem="onEditLeftItem($event)"
                              @deleteItem="onDeleteLeftItem($event)"
                              :items="footer.left.items"
                              @change="onLSectionChange($event)"
                              class="mt-1">
                </left-section>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-0">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <h6 v-b-toggle.accordion-2> Right Section Items</h6>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <right-section @deleteSection="onDeleteRightSection"
                               @change="onRSectionChange($event)"
                               @saveSection="onAddRightSection($event)"
                               @editSection="onEditRightSection($event)"
                               :sections="footer.right.sections"
                               class="mt-1"></right-section>
              </b-collapse>
            </b-card>
          </div>
        </b-tab>

        <div
            class="mt-4 d-flex flex-column"
            style="gap: 5px"
        >
          <b-button
              :disabled="loading || invalid"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
