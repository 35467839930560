<template>
  <nav class="links-container" ref="greedy" :style="navStyle">
    <ul ref="links" class="links-list" v-show="shouldShowMenu">
      <li
          v-for="(link, i) in visibleLinks"
          :key="i"
          :class="['link-cont', `order-${link.position}`]"
          @mouseover="handleMouseOver(link)"
          @mouseleave="handleMouseLeave"
      >
        <template v-if="link.buttonType === 'submenu'">
          <p :style="linkStyle" class="link my-0">{{ link.title }}</p>
          <ul
              v-if="dropItems && link.buttonType === 'submenu'"
              :class="['ul-drop', `order-${link.position}`]"
          >
            <li
                v-for="item in link.children"
                :key="item.id"
                :class="['ul-drop-item', `order-${item.position}`]"
            >
              <a :style="linkStyle" class="link">
                {{ item.title }}
              </a>
            </li>
          </ul>
        </template>
        <a
            v-else
            class="link px-4  m-auto text-center text-nowrap d-inline-block"
            :class="`order-${link.position}`"
            :style="linkStyle"
        >
          {{ link.title }}
        </a>
      </li>
    </ul>

    <div v-if="shouldShowHiddenLinks" class="drop">
      <button
          class="more-btn link-cont"
          :style="{ color: menu.color }"
          ref="greedyButton"
          @mouseover="showHiddenLinks = true"
          @mouseleave="showHiddenLinks = false"
      >
        <span
            class="px-4 capitalize text-center games"
        >
          + juegos
        </span>
      </button>
      <ul
          :style="hiddenLinksStyle"
          class="hidden-links-list"
          ref="hiddenLinks"
          @mouseover="showHiddenLinks = true"
          @mouseleave="showHiddenLinks = false"
      >
        <li v-for="item in hiddenLinks" :key="item.id" class="link-cont">
          <a
              class="link w-full px-4 py-2 text-center text-nowrap d-inline-block"
              :style="linkStyle"
          >
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "GodMoneyNav",
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropItems: false,
      showHiddenLinks: false,
      visibleLinks: [],
      hiddenLinks: [],
    };
  },
  computed: {
    shouldShowMenu() {
      return this.menu
    },

    shouldShowHiddenLinks() {
      return this.shouldShowMenu && this.hiddenLinks.length > 0;
    },

    navStyle() {
      const {background_color, background_image, color} = this.menu;

      const style = {
        "--background-color": background_color || "black",
        "--background-color-hover": this.menu.background_hover_color || "",
        "--color": color || "white",
        "--color-hover": this.menu.hover_color || "black",
        // backgroundColor:
        //     "color-mix( in srgb,var(--background-color) 100%,transparent 80%)",
        backgroundColor: "transparent",
        backgroundImage: background_image ? `url('${background_image}')` : 'none',
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
      };

      // if (background_image.length) {

      // }
    },

    linkStyle() {
      return {
        fontSize: this.menu.font_size ? `${this.menu.font_size}px` : "1rem",
        // height: "54px",
        textDecoration: "none",
        textAlign: "center",
        color: this.menu.color || "white",
      };
    },

    hiddenLinksStyle() {
      return {
        ...this.linkStyle,
        backgroundColor: this.menu.background_color || "black",
      };
    },

    filteredLinks() {
      return this.menu.links;
    },
  },
  methods: {
    handleMouseOver(link) {
      if (link.buttonType === "submenu") {
        this.dropItems = true;
      }
    },
    handleMouseLeave() {
      this.dropItems = false;
    },
    updateLinks() {
      const nav = this.$refs.greedy;
      if (!nav) return;

      const availableSpace = nav.clientWidth - 150;
      let totalWidth = 0;
      this.visibleLinks = [];
      this.hiddenLinks = [];

      for (const link of this.filteredLinks) {
        const linkWidth = this.getTextWidth(link.title);
        if (totalWidth + linkWidth <= availableSpace) {
          this.visibleLinks.push(link);
          totalWidth += linkWidth;
        } else {
          this.hiddenLinks.push(link);
        }
      }
    },

    sortMenuLinks() {
      const links = this.menu.links;
      if (links.length > 0) {
        links.sort((a, b) => Number(a.position) - Number(b.position));
      }
      this.menu.links = links;
    },

    getTextWidth(text) {
      // Implement a more accurate text width calculation if needed
      return text.length * 15; // Rough estimate
    },
  },

  watch: {
    "menu.links": {
      handler() {
        this.updateLinks()
      },
      deep: true
    }
  },

  mounted() {
    this.updateLinks();
    this.sortMenuLinks();
    window.addEventListener("resize", this.updateLinks);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateLinks);
  },
};
</script>

<style scoped>
.links-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.links-container ul {
  display: flex;
  margin: 0;
  align-items: center;
  padding: 0;
  list-style-type: none;
}

.links-container .links-list {
  height: 100% !important;
}

.link {
  font-weight: bold;
  transition: background-color 0.5s, color 0.5s;
  display: grid;
  place-content: center;
  height: 100%;
}

.link:hover {
  background-color: var(--background-color-hover);
  color: var(--color-hover) !important;
}

.link-cont {
  width: 100%;
  position: relative;
  height: 100%;
}

.drop {
  position: relative;
  display: inline-block;
}

.ul-drop {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.ul-drop-item {
  background-color: var(--background-color);
  list-style: none;
  width: 100%;
}

.more-btn {
  //background: var(--background-color);
  background: transparent;
  border: none;
  min-width: 7rem;
  font-weight: bold;
  cursor: pointer;
  color: var(--color);
}

.hidden-links-list {
  position: absolute;
  z-index: 2000;
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  padding: 0;
  justify-content: center;
}

.games {
  white-space: nowrap;
  display: inline-block;
  font-family: var(--body-font), sans-serif;
}

.link-cont a {
  display: inline-block;
  font-family: var(--body-font), sans-serif;
}
</style>
