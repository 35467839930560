<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import SocialLinksStoreModule
  from "@/views/components/whitelabel-templates/GodMoney/config/PageEdit/SocialLinksManagement/store/socialLinksStoreModule";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "index",
  components: {
    ItemList,
    ItemForm,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "live_tables",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const GM_SOCIAL_LINKS_STORE_MODULE_NAME = "gm-social-links-store";
    const socialLinks = ref({
      name: '',
      title: '',
      links: [],
    });

    const rBuild = RequestBuilder();
    const item = ref({
      href: "",
      svgData: "",
    });

    const {
      settings,
      editTemplate,
      showForm,
      enableEditMode,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      enableAddMode,
      resetForm,
      showList,
      setIndex
    } = UseIndex();

    if (!store.hasModule(GM_SOCIAL_LINKS_STORE_MODULE_NAME))
      store.registerModule(GM_SOCIAL_LINKS_STORE_MODULE_NAME, SocialLinksStoreModule);

    onMounted(() => {
      resetSocialLinksItem();
      const {itemsSelected} = props.options;
      if (itemsSelected) socialLinks.value = itemsSelected;
      updatePreview();
    });

    const resetItem = () => {
      item.value = {
        href: "",
        svgData: ""
      };
    };

    const onEditItem = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      item.value = val.item;
    };

    const onAddItem = () => {
      showForm();
      enableAddMode();
      resetItem();
      updatePreview();
    };


    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetForm();
      showList();
      updatePreview();
    };

    const loadItemFromCache = (val) => {
      socialLinks.value.links[val.index] = val.item
    }

    const onDeleteItem = (index) => {
      socialLinks.value.links.splice(index, 1)
      updatePreview();
    };


    const saveItem = () => {
      socialLinks.value.links.push(item.value);
      updatePreview();
      showList();
      resetAll();
    };

    const updateLink = () => {
      socialLinks.value.links[settings.value.index] = item.value
      updatePreview();
      showList();
      resetAll();
    };

    const isEditMode = computed(() => props.options.action === "edit");

    const onSubmit = () => {
      if (isEditMode.value) {
        updateLiveTablesItem();
      } else {
        createLiveTablesItem();
      }
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch("app-whitelabel-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: "templateData.social_links"
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createLiveTablesItem = async () => {
      loading.value = true;
      socialLinks.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      socialLinks.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "gm-social-links-store/addSocialLinksItem",
            rBuild.clean(socialLinks.value)
        );

        showSuccessToast(toast, 'Social Links', 'Social tables item created');
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            'Error creating or updating Live Tables Item',
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateLiveTablesItem = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("gm-social-links-store/updateSocialLinksItem", {
          id: socialLinks.value._id,
          socialLinksData: rBuild.clean(socialLinks.value),
        });

        showSuccessToast(toast, 'Social Links', 'Social Links item updated');
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            'Error creating or updating social links item',
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetSocialLinksItem = () => {
      socialLinks.value = {
        name: '',
        title: '',
        links: [],
      };
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.socialLinks");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          socialLinks: value || socialLinks.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const onIconSelected = (icon, index) => {
      socialLinks.value.links[index].svgData = icon.value;
    };

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      socialLinks,
      loading,
      onAddItem,
      settings,
      onEditItem,
      onDeleteItem,
      resetAll,
      updateLink,
      item,
      updatePreview,
      saveItem,
      onIconSelected,

    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          v-if="socialLinks"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-list-group-item style="background: none" class="px-0">
            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.name") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="socialLinks.name"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="title" label-for="g-title">
                    <template #label>{{ $t("title") }}</template>
                    <b-form-textarea
                        rows="4"
                        id="g-title"
                        type="text"
                        v-model="socialLinks.title"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

          </b-list-group-item>

        </b-tab>

        <b-tab title="Settings">
          <item-list
              :items="socialLinks.links"
              v-if="settings.status === 'list'"
              @add="onAddItem()"
              @edit="onEditItem($event)"
              @delete="onDeleteItem($event)"
          >
          </item-list>
          <item-form
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveItem()"
              @update="updateLink()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :item="item"
              :icon-list="['Facebook', 'Instagram', 'linkedin', 'twitter']"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </item-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
