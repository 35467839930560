<script>
import {BButton, BCol, BForm, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import {showErrorToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import i18n from "@/libs/i18n";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {useToast} from "vue-toastification/composition";

export default {
  name: "ContactEdit",
  components: {BButton, BFormInput, BFormGroup, BCol, ValidationProvider, ValidationObserver, BForm, BRow},

  setup() {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const toast = useToast();

    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );

    const contact = ref({
      whatsApp: '',
      allowWhatsApp: false,
      allowTawk:false,
      urlTawk:''
    })

    const editTemplate = computed(
        () => store.state[MODULE_APP_STORE_NAME].editTemplate
    );

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.contact'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    }

    const onSubmit = () => {
      editTemplate.value.templateData.contact = contact.value
      updateTemplate()
    }

    const resetData = () => {
      contact.value = {
        whatsApp: '',
        allowWhatsApp: false,
        allowTawk:false,
        urlTawk:''
      }
    }

    onMounted(() => {
      contact.value.whatsApp = editTemplate.value.templateData.contact.whatsApp
      contact.value.allowWhatsApp = editTemplate.value.templateData.contact.allowWhatsApp
      contact.value.urlTawk = editTemplate.value.templateData.contact.urlTawk
      contact.value.allowTawk = editTemplate.value.templateData.contact.allowTawk
    })

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      editTemplate,
      updateTemplate,
      ValidationProvider,
      ValidationObserver,
      onSubmit,
      resetData,
      contact
    }
  },

  mounted() {
    //load value from template
  },

}
</script>

<template>
  <div>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
      >
        <b-row>
         
            <b-col cols="12">
              <b-form-group>
                  <b-form-checkbox
                      name="allowWhatsApp"
                      v-model="contact.allowWhatsApp"
                      switch
                  >
                    {{ $t("labels.allowWhatsapp") }}
                    </b-form-checkbox>                
              </b-form-group>
              <validation-provider
                class="w-100"
                #default="validationContext"
                name="WhatsApp"
                rules="required"
                >
              <b-form-group label="name" label-for="h-name" v-if="contact.allowWhatsApp">
                <template #label>WhatsApp</template>
                <b-form-input
                    id="tel-w"
                    type="tel"
                    v-model="contact.whatsApp"
                    :state="getValidationState(validationContext)"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
              <b-form-group>
                  <b-form-checkbox
                      name="allowTawk"
                      v-model="contact.allowTawk"
                      switch
                  >
                    {{ $t("labels.allowTawk") }}
                    </b-form-checkbox>                
              </b-form-group>
              <validation-provider
                class="w-100"
                #default="validationContext"
                name="urlTawk"
                rules="required"
                v-if="contact.allowTawk"
                >
              <b-form-group label="name" label-for="h-name" v-if="contact.allowTawk">
                <template #label>URL Tawk</template>
                <b-form-input
                    id="tel-w"
                    type="tel"
                    v-model="contact.urlTawk"
                    :state="getValidationState(validationContext)"
                    placeholder="https://tawk.to/chat/64asdasdasdas76asdfasf/23easdas211q"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
            </b-col>
         
        </b-row>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{ $t('buttons.save') }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<style scoped lang="scss">

</style>