<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import LiveTablesStoreModule from "./store/liveTablesStoreModule";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required} from "@core/utils/validations/validations";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "index",
  components: {
    ItemForm,
    ItemList,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "live_tables",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const GM_LIVE_TABLES_STORE_MODULE_NAME = "gm-live-tables";
    const liveTables = ref({
      name: '',
      title: '',
      tables: [],
    });

    const rBuild = RequestBuilder();

    const {
      settings,
      editTemplate,
      showForm,
      enableEditMode,
      enableAddMode,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      showList,
      setIndex
    } = UseIndex();

    // Register modules
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(GM_LIVE_TABLES_STORE_MODULE_NAME))
      store.registerModule(GM_LIVE_TABLES_STORE_MODULE_NAME, LiveTablesStoreModule);

    onMounted(() => {
      resetLiveTablesItem();
      const {itemsSelected} = props.options;
      if (itemsSelected) liveTables.value = itemsSelected;
      updatePreview();
    });


    const isEditMode = computed(() => props.options.action === "edit");

    const onSubmit = () => {
      if (isEditMode.value) {
        updateLiveTablesItem();
      } else {
        createLiveTablesItem();
      }
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch("app-whitelabel-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: "templateData.live_tables"
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createLiveTablesItem = async () => {
      loading.value = true;
      liveTables.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      liveTables.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "gm-live-tables/addLiveTablesItem",
            rBuild.clean(liveTables.value)
        );

        showSuccessToast(toast, 'Live Tables', 'Live tables item created');
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            'Error creating or updating Live Tables Item',
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateLiveTablesItem = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("gm-live-tables/updateLiveTablesItem", {
          id: liveTables.value._id,
          liveTablesData: rBuild.clean(liveTables.value),
        });

        showSuccessToast(toast, 'Live Tables', 'Live Tables item updated');
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            'Error creating or updating live tables item',
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetLiveTablesItem = () => {
      liveTables.value = {
        name: '',
        title: '',
        tables: [],
      };
    };


    const table = ref({
      src: '',
      href: ''
    })


    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.liveTables");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };


    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          liveTables: value || liveTables.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onEditTable = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      table.value = val.item;
    };


    const onDeleteTable = (index) => {
      liveTables.value.tables.splice(index, 1)
      updatePreview();
    };

    const resetTable = () => {
      table.value = {
        href: "",
        src: ""
      };
    };


    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetTable();
      showList();
    };

    const loadItemFromCache = (val) => {
      liveTables.value.tables[val.index] = val.item
    }

    const saveTable = () => {
      liveTables.value.tables.push(table.value);
      updatePreview();
      showList();
      resetAll();
    }


    const onAddTable = () => {
      showForm();
      enableAddMode();
      resetTable();
      updatePreview();
    };

    const updateTable = () => {
      liveTables.value.tables[settings.value.index] = table.value
      updatePreview();
      showList();
      resetAll();
    };

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      liveTables,
      saveTable,
      updateTable,
      onEditTable,
      loading,
      onDeleteTable,
      updatePreview,
      errorInLoadImage,
      settings,
      table,
      resetAll,
      onAddTable
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          v-if="liveTables"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-list-group-item style="background: none" class="px-0">
            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.name") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="liveTables.name"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="title" label-for="g-title">
                    <template #label>{{ $t("title") }}</template>
                    <b-form-textarea
                        rows="4"
                        id="g-title"
                        type="text"
                        v-model="liveTables.title"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

          </b-list-group-item>

        </b-tab>

        <b-tab title="Settings">
          <item-list
              :max-count="5"
              :items="liveTables.tables"
              v-if="settings.status === 'list'"
              @add="onAddTable()"
              @edit="onEditTable($event)"
              @delete="onDeleteTable($event)"
          />

          <item-form
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveTable()"
              @update="updateTable()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :size-validation="true"
              :size="'184x368'"
              :item="table"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </item-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
