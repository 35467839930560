<script>
import GodMoneyNav from "@/views/components/whitelabel-templates/GodMoney/components/common/navMenu/GodMoneyNav.vue";
import GodMoneyRegister
  from "@/views/components/whitelabel-templates/GodMoney/components/modules/auth/register/GodMoneyRegister.vue";
import GodMoneyLogin
  from "@/views/components/whitelabel-templates/GodMoney/components/modules/auth/login/GodMoneyLogin.vue";
import GodMoneySideNav
  from "@/views/components/whitelabel-templates/GodMoney/components/common/navMenu/GodMoneySideNav.vue";

export default {
  name: "GodMoneyHeader",
  components: {GodMoneySideNav, GodMoneyRegister, GodMoneyNav, GodMoneyLogin},

  props: ["header", "menu", "appNavKey"],

  data() {
    return {
      isMenuOpen: false,
    };
  },

  computed: {
    showLogo() {
      return this.header.logo && !this.header.logo.hide;
    },
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<template>
  <header class="ganamos-header">
  <div class="ganamos-header-left">
      <!-- burger -->
      <div class="d-flex align-items-center">
        <god-money-side-nav v-if="menu" :menu="menu"/>

        <a v-if="showLogo" class="logo">
          <img :src="header.logo.href" alt="header-logo"/>
        </a>

        <p v-else class="logo">
          LOGO
        </p>
      </div>
    </div>

    <div class="ganamos-header-links">
      <god-money-nav v-if="menu" :menu="menu" :key="appNavKey"/>
    </div>

  <div class="ganamos-header-right">
      <div class="d-flex align-items-center gap-2">
        <god-money-register v-if="!header.auth.hide" :config="header.auth" class="mr-2"/>
        <god-money-login
            v-if="!header.auth.hide"
            :config="header.auth"
            ref="userLogin"
        />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "../../../common/assets/mixins.scss";
.ganamos-header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 50px;
  gap: 10px;
  color: var(--header-c);
  background-color: var(--header-bg);
  animation: fadeInDown 0.2s ease-in-out;
  will-change: transform, opacity;
  position: fixed;

  @include responsive(lg) {
    padding: 0 30px;
  }

  &::before,
  &::after {
    display: block;
    position: absolute;
    width: 7rem;
    height: 150%;
    border-radius: 50%;
    -webkit-filter: blur(2rem);
    filter: blur(2rem);
    background-color: var(--header-light);
    top: 0;
    z-index: -1;

    @include responsive(lg) {
      content: "";
    }
  }

  &::before {
    left: 0;
    transform: translate(-20%, -50%);
  }

  &::after {
    right: 0;
    transform: translate(20%, -50%);
  }

  &-left {
    display: flex;
    align-items: center;
    gap: 50px;
    padding-right: 20px;

    .logo {
      height: 30px;

      img {
        height: 100%;
        max-width: max-content;
      }
    }
  }

  &-links {
    position: fixed;
    transform: translateX(-200%);
    transition: .5s;

    @media (min-width: 1024px) {
      position: static;
      width: 100%;
      height: auto;
      transform: translateX(0);
    }

  }

  &-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .header-auth {
    &-login {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 600;
      gap: 0.5rem;
    }

    &-separator {
      width: 1px;
      height: 0.8rem;
      background-color: white;
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>

